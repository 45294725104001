import { useCookies } from 'react-cookie'
import { Button, Offcanvas, Row, Stack } from 'react-bootstrap'
// import { Dispatch, SetStateAction } from 'react'

// expire cookies after four months
const COOKIES_CONSENT_EXPIRATION_DAYS = 120 * 24 * 60 * 60 * 1000

/* interface CookiesConsentBannerProps {
    setShowCookiesConfig: Dispatch<SetStateAction<boolean>>
} */

const CookiesConsentBanner = (/* { setShowCookiesConfig }: CookiesConsentBannerProps */) => {
    const [cookies, setCookie] = useCookies(['consent'])

    return (
        <Offcanvas
            show={!cookies.consent}
            placement='bottom'
            backdrop='static'
            style={{ width: '100%', height: '20%' }}
        >
            <Offcanvas.Header className='pb-0'>
                <Offcanvas.Title as='h4'>Hinweis zu Cookies</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='text-color-n3'>
                <Stack direction='vertical' gap={1}>
                    <Row className='align-items-center'>
                        <div className='col-md-8 col-lg-8 col-xl-7 col-xxl-10'>
                            <Stack direction='vertical' gap={2}>
                                <span className='ds-cookies-text'>
                                    Wenn Sie auf „Alle Cookies akzeptieren“
                                    klicken, stimmen Sie der Speicherung von
                                    Cookies auf Ihrem Gerät zu, um die
                                    Websitenavigation zu verbessern, die
                                    Websitenutzung zu analysieren und unsere
                                    Marketingbemühungen zu unterstützen.
                                </span>
                            </Stack>
                        </div>
                        <div className='col-md-4 col-lg-4 col-xl-5 col-xxl-2'>
                            <Stack
                                direction='horizontal'
                                gap={2}
                                className='w-100 justify-content-center my-3'
                            >
                                {/* <Button
                                    variant='secondary'
                                    onClick={() => {
                                        setShowCookiesConfig(true)
                                    }}
                                >
                                    Cookies-Einstellung
                                </Button> */}
                                <Button
                                    variant='primary'
                                    onClick={() => {
                                        const now = new Date()

                                        setCookie('consent', true, {
                                            path: '/',
                                            expires: new Date(
                                                now.getTime() +
                                                    COOKIES_CONSENT_EXPIRATION_DAYS
                                            ),
                                            sameSite: true,
                                        })

                                        console.log(cookies.consent)
                                    }}
                                >
                                    Alle Cookies akzeptieren
                                </Button>
                            </Stack>
                        </div>
                    </Row>
                    <Stack
                        direction='horizontal'
                        gap={2}
                        className='flex-start'
                    >
                        {[
                            ['Cookies Richtlinien', 'cookies.html'],
                            ['Impressum', 'impressum'],
                            ['Datenschutz', 'datenschutz'],
                        ].map(([linkText, linkPostfix], index) => (
                            <>
                                {index > 0 && <> | </>}
                                <a
                                    className='ds-cookies-link text-color-n3'
                                    href={`https://www.ds-destinationsolutions.com/${linkPostfix}`}
                                    aria-label='DS Destination Solutions Cookies Richtlinien'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {linkText}
                                </a>
                            </>
                        ))}
                    </Stack>
                </Stack>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default CookiesConsentBanner
