import * as yup from 'yup'

import {
    emailValidation,
    passwordConfirmationValidation,
    passwordValidation,
} from 'common/validationSchema'

const changePasswordValidationSchema = yup.object({
    password: passwordValidation,
    newPassword: passwordValidation,
    passwordConfirmation: passwordConfirmationValidation,
})

const requestEmailChangeValidationSchema = yup.object({
    newEmail: emailValidation,
})

export { changePasswordValidationSchema, requestEmailChangeValidationSchema }
