import * as yup from 'yup'

const emailValidation = yup
    .string()
    .email('Email muss gültig sein')
    .required('Pflichtfeld')

// the solution was inspired on the following answer from stack overflow:
// https://stackoverflow.com/questions/58767980/aws-cognito-password-regex-specific-to-aws-cognito
const passwordValidation = yup
    .string()
    .required('Pflichtfeld')
    .min(8, 'Das Passwort muss mindestens 8 Zeichen lang sein')
    .matches(/[a-z]/, 'Das Passwort muss einen Kleinbuchstaben enthalten')
    .matches(/[A-Z]/, 'Das Passwort muss einen Großbuchstaben enthalten')
    .matches(/[0-9]/, 'Das Passwort muss eine Zahl enthalten')
    .matches(
        /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/,
        'Das Passwort muss ein Sonderzeichen enthalten'
    )

const passwordConfirmationValidation = passwordValidation.oneOf(
    [yup.ref('newPassword'), null],
    'Passwörter müssen übereinstimmen'
)

export { emailValidation, passwordValidation, passwordConfirmationValidation }
