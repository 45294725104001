import * as yup from 'yup'

import {
    emailValidation,
    passwordConfirmationValidation,
    passwordValidation,
} from 'common/validationSchema'

const loginValidationSchema = yup.object({
    email: emailValidation,
    password: passwordValidation,
})

const setPasswordValidationSchema = yup.object({
    newPassword: passwordValidation,
    passwordConfirmation: passwordConfirmationValidation,
})

const requestPasswordChangeValidationSchema = yup.object({
    email: emailValidation,
})

const confirmEmailChangeValidationSchema = yup.object({
    password: passwordValidation,
})

export {
    loginValidationSchema,
    setPasswordValidationSchema,
    requestPasswordChangeValidationSchema,
    confirmEmailChangeValidationSchema,
}
