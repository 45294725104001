import { EXECUTION_DAY, EXPORT_FORMAT, MappedTicket } from './types'

import { TICKET_STATUS } from '../../common/types'

const getTicketFileName = (exportFormat: EXPORT_FORMAT) =>
    `export_${new Date().getTime()}_tickets.${exportFormat}`

const getWeekDates = () => {
    return Array(8)
        .fill(new Date().setHours(0, 0, 0, 0))
        .map(
            (timestamp, index) =>
                new Date(timestamp + index * 24 * 60 * 60 * 1000)
        )
}

const isSameDay = (dayOne: Date, dayTwo: Date) => {
    return (
        dayOne.toLocaleDateString('de-DE') ===
        dayTwo.toLocaleDateString('de-DE')
    )
}

const isExecutionSoon = (date: Date) => {
    const now = new Date()

    if (isSameDay(now, date)) {
        return EXECUTION_DAY.TODAY
    } else if (date.getMonth() === now.getMonth()) {
        if (date.getDate() - now.getDate() === 1) {
            return EXECUTION_DAY.TOMORROW
        } else if (date.getDate() - now.getDate() === 2) {
            return EXECUTION_DAY.DAY_AFTER_TOMORROW
        }
    }

    return EXECUTION_DAY.SOON
}

const formatExecutionDay = (date: Date) => {
    switch (isExecutionSoon(date)) {
        case EXECUTION_DAY.TODAY:
            return 'heute'
        case EXECUTION_DAY.TOMORROW:
            return 'morgen'
        case EXECUTION_DAY.DAY_AFTER_TOMORROW:
            return 'übermorgen'
        default:
            return `geplant am ${date.toLocaleDateString('DE')}`
    }
}

const formatDateToLocaleTimeString = (date: Date) => {
    return date.toLocaleString() + ' Uhr'
}

const isInvalidPlannedTimestamp = (ticket: MappedTicket) => {
    return (
        ticket.plannedExecutionTimestamp < ticket.earliestExecutionTimestamp ||
        ticket.plannedExecutionTimestamp > ticket.latestExecutionTimestamp
    )
}

const isTicketInProgress = (ticket: MappedTicket) => {
    if (
        ticket.latestExecutionTimestamp === undefined ||
        (ticket.inProgress !== undefined && ticket.inProgress === 'true')
    ) {
        return true
    }

    return false
}

/**
 * Returns mapped ticket status
 *
 * Rules:
 * if
 *     given status == TICKET_STATUS_DONE
 * then return TICKET_STATUS_PROPOSED_DONE
 * else given status
 *
 * @param status
 */
const getMappedTicketStatus = (status: number) => {
    return status === TICKET_STATUS.DONE ? TICKET_STATUS.PROPOSED_DONE : status
}

export {
    getTicketFileName,
    getWeekDates,
    isSameDay,
    isExecutionSoon,
    formatExecutionDay,
    formatDateToLocaleTimeString,
    isInvalidPlannedTimestamp,
    getMappedTicketStatus,
    isTicketInProgress,
}
