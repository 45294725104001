import { Fragment, useState } from 'react'
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBroom,
    faClock,
    faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons'

import { EXECUTION_DAY, TicketItemProps } from './types'
import { TICKET_KIND_MAP } from 'common/types'
import TicketDetails from './TicketDetails'
import { useTicketAlert } from './hooks'
import QRCode from 'react-qr-code'
import { formatExecutionDay, isExecutionSoon, isSameDay } from './utils'

const TicketItem = ({ ticket, index }: TicketItemProps) => {
    const [showTicketDetails, setShowTicketDetails] = useState<boolean>(false)
    const { isTicketChanged } = useTicketAlert()

    const showTicketChangeAlert = isTicketChanged(ticket)
    const tooltipId = `tooltip-ticket-${index}`
    const nowDate = new Date()
    const plannedExecutionDate = new Date(ticket.plannedExecutionTimestamp)
    const latestExecutionDate = new Date(ticket.latestExecutionTimestamp)
    const timeDiff = latestExecutionDate.getTime() - nowDate.getTime()
    const dayDiff = timeDiff / (1000 * 3600 * 24)
    const dueAlertColor =
        dayDiff < 3 ? `ds-color-${dayDiff < 1 ? 'danger' : 'warning'}` : ''

    return (
        <Col>
            <div className='card ds-card'>
                <TicketDetails
                    ticket={ticket}
                    dueAlertColor={dueAlertColor}
                    showTicketChangeAlert={showTicketChangeAlert}
                    plannedExecutionDate={plannedExecutionDate}
                    latestExecutionDate={latestExecutionDate}
                    showModal={showTicketDetails}
                    setShowModal={setShowTicketDetails}
                />
                <div
                    className='d-flex'
                    onClick={() => setShowTicketDetails(true)}
                >
                    <div className='ms-auto flex-shrink-1'>
                        <div className='ds-card-icon d-flex align-items-center justify-content-center'>
                            <i>
                                <FontAwesomeIcon icon={faBroom} />
                            </i>
                        </div>
                    </div>
                    <div className='ds-padding-left-0-5 w-100 ds-mouse-pointer'>
                        <small className='text-color-n3'>
                            {ticket.shortId}&nbsp;
                            <i className={dueAlertColor}>
                                <FontAwesomeIcon icon={faClock} />
                            </i>
                            &nbsp;
                            {formatExecutionDay(
                                new Date(ticket.plannedExecutionTimestamp)
                            )}
                            {isExecutionSoon(
                                new Date(ticket.plannedExecutionTimestamp)
                            ) === EXECUTION_DAY.TODAY &&
                                isSameDay(
                                    new Date(ticket.plannedExecutionTimestamp),
                                    new Date(ticket.latestExecutionTimestamp)
                                ) && (
                                    <Fragment>
                                        &nbsp;von&nbsp;
                                        {plannedExecutionDate.toLocaleTimeString(
                                            'de-DE',
                                            {
                                                timeZone: 'Europe/Berlin',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            }
                                        )}
                                        &nbsp;Uhr
                                    </Fragment>
                                )}
                            {isExecutionSoon(
                                new Date(ticket.plannedExecutionTimestamp)
                            ) === EXECUTION_DAY.TODAY &&
                                !isSameDay(
                                    new Date(ticket.plannedExecutionTimestamp),
                                    new Date(ticket.latestExecutionTimestamp)
                                ) && (
                                    <Fragment>
                                        &nbsp;um&nbsp;
                                        {plannedExecutionDate.toLocaleTimeString(
                                            'de-DE',
                                            {
                                                timeZone: 'Europe/Berlin',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                            }
                                        )}
                                        &nbsp;Uhr
                                    </Fragment>
                                )}
                            {isSameDay(
                                new Date(ticket.plannedExecutionTimestamp),
                                new Date(ticket.latestExecutionTimestamp)
                            ) && (
                                <Fragment>
                                    &nbsp;bis&nbsp;
                                    {latestExecutionDate.toLocaleTimeString(
                                        'de-DE',
                                        {
                                            timeZone: 'Europe/Berlin',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                        }
                                    )}
                                    &nbsp;Uhr
                                </Fragment>
                            )}
                        </small>
                        <div className='hidden'>
                            <QRCode
                                value={`{"bsRoomId": ${ticket.bsRoomId}}`}
                                id={`bsRoomId-${ticket.id}`}
                                bgColor='#ffffff'
                                fgColor='#000000'
                                size={120}
                            ></QRCode>
                        </div>
                        <div className='text-break fw-bold'>
                            {TICKET_KIND_MAP[ticket.kind]}
                        </div>
                        <div className='text-color-n3 ds-card-room-name'>
                            {ticket.bsRoomName} (Wohneinheit-Id:{' '}
                            {ticket.bsRoomId})
                        </div>
                    </div>
                    {showTicketChangeAlert && (
                        <div className='ms-auto align-self-center p-3'>
                            <OverlayTrigger
                                placement='top'
                                overlay={props => (
                                    <Tooltip id={tooltipId} {...props}>
                                        Es ist eine Ticketänderung aufgetreten.
                                        Die geplante Reinigungszeit liegt
                                        außerhalb des möglichen
                                        Reinigungszeitraums. Bitte ändern Sie
                                        die geplante Reinigungszeit.
                                    </Tooltip>
                                )}
                            >
                                <i className='fa-solid fa-triangle-exclamation ds-ticket-alert'>
                                    <FontAwesomeIcon
                                        icon={faTriangleExclamation}
                                    />
                                </i>
                            </OverlayTrigger>
                        </div>
                    )}
                </div>
            </div>
        </Col>
    )
}

export default TicketItem
