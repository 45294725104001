import { ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { selectCurrentUser } from 'features/auth/authSlice'

interface AuthProviderProps {
    children: ReactNode
}

const ProtectedPage = ({ children }: AuthProviderProps) => {
    const navigate = useNavigate()

    const user = useSelector(selectCurrentUser)

    useEffect(() => {
        if (!user) {
            navigate('/login')
        }
    })

    return <>{children}</>
}

export default ProtectedPage
