import { chain } from 'lodash'
import jsPDF from 'jspdf'

import {
    EXPORT_FORMAT,
    FILTER_BY_STATUS,
    GROUP_BY,
    SEARCH_BY_KEY,
    MappedTicket,
    WeekTicketsGroup,
} from './types'
import { TICKET_STATUS, Ticket, TICKET_STATUS_MAP } from 'common/types'
import {
    getTicketFileName,
    getWeekDates,
    isInvalidPlannedTimestamp,
} from './utils'

const useTickets = () => {
    const sortTicketsByDate = (tickets: Ticket[]) =>
        tickets
            .slice()
            .sort(
                (a, b) =>
                    a.plannedExecutionTimestamp - b.plannedExecutionTimestamp
            )

    const prepareTickets = (tickets: Ticket[]) =>
        mapBsObjectIdToTicket(sortTicketsByDate(tickets))

    const sortTicketsByHasChanged = (
        tickets: Ticket[],
        alertTickets: Ticket[]
    ) => {
        const changedTickets = tickets.filter(ticket => {
            const index = alertTickets.findIndex(
                (item: Ticket) => item.id === ticket.id
            )

            return index === -1
        })

        const otherTickets = tickets.filter(item => {
            return !changedTickets.find(
                changedTickets => changedTickets.id === item.id
            )
        })

        return otherTickets.concat(changedTickets)
    }

    const mapBsObjectIdToTicket = (tickets: Ticket[]) =>
        tickets.map(ticket => {
            return {
                ...ticket,
                bsObjectId: ticket.objectId,
                bsObjectName: ticket.objectName,
                address: ticket.objectAddress,
                bsRoomName: ticket.roomName,
            }
        })

    const filterTicketsByStatus = (
        tickets: MappedTicket[],
        filterValue: FILTER_BY_STATUS
    ) => {
        const actualFilterValue =
            filterValue === FILTER_BY_STATUS.Fertig ? [2, 3] : [filterValue]

        return tickets.filter(ticket =>
            actualFilterValue.includes(ticket.status)
        )
    }

    const filterTicketsByPlannedDate = (
        tickets: MappedTicket[],
        { startDate, endDate }: { startDate: Date; endDate: Date }
    ) => {
        const endDateCopy = new Date(endDate.getTime())
        const endOfEndDate = new Date(
            endDateCopy.setDate(endDateCopy.getDate() + 1)
        )

        return tickets.filter(ticket => {
            const plannedExecutionDate = new Date(
                ticket.plannedExecutionTimestamp
            )

            return (
                plannedExecutionDate >= startDate &&
                plannedExecutionDate < endOfEndDate
            )
        })
    }

    const filterTicketsByKey = (
        tickets: MappedTicket[],
        filterKey: SEARCH_BY_KEY,
        filterValue: string
    ) =>
        tickets.filter(ticket =>
            ticket[filterKey]
                ?.toString()
                .toLowerCase()
                .includes(filterValue.toLowerCase())
        )

    const groupTicketsByKey = (tickets: MappedTicket[], groupByKey: GROUP_BY) =>
        chain(tickets)
            .groupBy(groupByKey)
            .map((value, key) => ({ groupId: key, tickets: value }))
            .value()

    const groupWeekTicketsByDay = (tickets: Ticket[]) => {
        const weekDates = getWeekDates()

        const overviewTickets = tickets.filter(
            ticket =>
                ticket.status === TICKET_STATUS.OPEN &&
                ticket.plannedExecutionTimestamp >= weekDates[0].getTime() &&
                ticket.plannedExecutionTimestamp < weekDates[7].getTime()
        )

        const groupedOverviewTickets = overviewTickets.reduce(
            (group, ticket) => {
                const plannedExecutionDate = new Date(
                    ticket.plannedExecutionTimestamp
                ).toDateString()
                group[plannedExecutionDate] = group[plannedExecutionDate] ?? []
                group[plannedExecutionDate].push(ticket)
                return group
            },
            {} as WeekTicketsGroup
        )

        return weekDates
            .slice(0, 7)
            .map(date => groupedOverviewTickets[date.toDateString()] ?? [])
    }

    const getMaxTicketDailyCount = (groupedWeekTickets: Ticket[][]) =>
        Math.max(
            ...Object.values(groupedWeekTickets).map(tickets => tickets.length)
        )

    return {
        sortTicketsByHasChanged,
        prepareTickets,
        filterTicketsByStatus,
        filterTicketsByKey,
        filterTicketsByPlannedDate,
        groupTicketsByKey,
        groupWeekTicketsByDay,
        getMaxTicketDailyCount,
    }
}

const useTicketAlert = () => {
    const isTicketChanged = (ticket: MappedTicket) => {
        return isInvalidPlannedTimestamp(ticket)
    }

    const getShowTicketsListChangeAlert = (tickets: MappedTicket[]) =>
        tickets.filter(ticket => isTicketChanged(ticket))

    return {
        isTicketChanged,
        getShowTicketsListChangeAlert,
    }
}

const useExportTickets = () => {
    const exportPdfTickets = (provider: any, tickets: MappedTicket[]) => {
        if (tickets.length > 20) {
            alert(
                'Es sind maximal 20 Tickets für den PDF Export zugelassen! Bitte schränken Sie die Filterung der Tickets weiter ein.'
            )
        } else {
            const doc = new jsPDF('portrait', 'pt')
            let now = new Date()
            const pageTimeout = 500

            tickets.forEach((ticket, index) => {
                let plannedDate = ticket.plannedExecutionTimestamp
                    ? new Date(ticket.plannedExecutionTimestamp)
                    : false
                let latestDate = ticket.latestExecutionTimestamp
                    ? new Date(ticket.latestExecutionTimestamp)
                    : false
                setTimeout(() => {
                    doc.setFontSize(11)
                    doc.setFont('helvetica', 'bold')
                    doc.text('Wohneinheit-Id: ' + ticket.bsRoomId, 400, 60)
                    doc.addSvgAsImage(
                        // @ts-ignore
                        document.getElementById(`bsRoomId-${ticket.id}`)
                            .outerHTML,
                        400,
                        70,
                        120,
                        120,
                        'qrcode' + ticket.id
                    )
                    doc.setFontSize(8)
                    doc.setFont('helvetica', 'normal')
                    doc.text('QR-Code der Wohneinheit', 410, 205)
                    doc.setFontSize(18)
                    doc.setFont('helvetica', 'bold')
                    doc.text('Reinigung Ticket: ' + ticket.shortId, 20, 40)
                    doc.setFontSize(10)
                    doc.setFont('helvetica', 'normal')
                    doc.text('Dienstleister: ' + provider.name, 20, 70)
                    doc.setFont('helvetica', 'bold')
                    doc.text(
                        'Status: ' + TICKET_STATUS_MAP[ticket.status],
                        20,
                        85
                    )
                    doc.setFont('helvetica', 'normal')
                    doc.text(
                        plannedDate instanceof Date
                            ? 'Geplant am: ' +
                                  plannedDate.toLocaleDateString('DE') +
                                  ' um ' +
                                  plannedDate.toLocaleTimeString('DE', {
                                      hour: 'numeric',
                                      minute: 'numeric',
                                  }) +
                                  ' Uhr'
                            : 'Geplant am:',
                        20,
                        100
                    )
                    doc.text(
                        latestDate instanceof Date
                            ? 'Fällig am: ' +
                                  latestDate.toLocaleDateString('DE') +
                                  ' um ' +
                                  latestDate.toLocaleTimeString('DE', {
                                      hour: 'numeric',
                                      minute: 'numeric',
                                  }) +
                                  ' Uhr'
                            : 'Fällig am:',
                        20,
                        115
                    )
                    doc.setFont('helvetica', 'bold')
                    doc.text('Objekt: ', 20, 130)
                    doc.setFont('helvetica', 'normal')
                    doc.text(ticket.bsObjectName || ' ', 20, 145)
                    doc.text(ticket.address?.street || ' ', 20, 160)
                    doc.text(
                        ticket.address?.zipcode + ' ' + ticket.address?.city,
                        20,
                        175
                    )
                    doc.setFont('helvetica', 'bold')
                    doc.text('Wohneinheit: ' + ticket.bsRoomId, 20, 190)
                    doc.setFont('helvetica', 'normal')
                    doc.text(ticket.bsRoomName || ' ', 20, 205)

                    let lineCount = 0
                    if (ticket.adults) {
                        doc.text(
                            '____________________________________________________________________________________________________',
                            20,
                            220
                        )
                        doc.setFont('helvetica', 'bold')
                        doc.text('Informationen aus der Buchung', 20, 235)
                        doc.setFont('helvetica', 'normal')
                        doc.text(
                            `Anreise: ${new Date(
                                ticket.arrival
                            ).toLocaleDateString('DE')} um ${new Date(
                                ticket.arrival
                            ).toLocaleTimeString('DE', {
                                hour: 'numeric',
                                minute: 'numeric',
                            })} Uhr`,
                            20,
                            250
                        )
                        doc.text(
                            `Abreise: ${new Date(
                                ticket.departure
                            ).toLocaleDateString('DE')} um ${new Date(
                                ticket.departure
                            ).toLocaleTimeString('DE', {
                                hour: 'numeric',
                                minute: 'numeric',
                            })} Uhr`,
                            20,
                            265
                        )
                        doc.text(`Anzahl Erwachsene: ${ticket.adults}`, 20, 280)

                        let childs: any = []
                        if (ticket.childs && ticket.childs.length > 0) {
                            ticket.childs.forEach(
                                (child: { age: number; count: number }) =>
                                    childs.push(
                                        `${child.age} Jahre (${child.count}x)`
                                    )
                            )

                            let linesChilds: any = doc.splitTextToSize(
                                childs.join(', '),
                                500
                            )
                            linesChilds?.forEach(
                                (lineChild: string, i: number) => {
                                    if (i === 0) {
                                        lineChild =
                                            'Anzahl Kinder: ' + lineChild
                                    }
                                    lineCount++
                                    doc.text(
                                        lineChild,
                                        20,
                                        280 + lineCount * 15
                                    )
                                }
                            )
                        } else {
                            lineCount++
                            doc.text(
                                'Anzahl Kinder: keine',
                                20,
                                280 + lineCount * 15
                            )
                        }
                        let services: any = []
                        if (
                            ticket.additionalServices &&
                            ticket.additionalServices.length > 0
                        ) {
                            ticket.additionalServices.forEach(service => {
                                services.push(
                                    `${service.as_name} (${service.as_count}x)`
                                )
                            })

                            let linesServices: any = doc.splitTextToSize(
                                services.join(', '),
                                500
                            )
                            linesServices?.forEach(
                                (lineService: string, i: number) => {
                                    if (i === 0) {
                                        lineService =
                                            'Zusatzleistungen: ' + lineService
                                    }
                                    lineCount++
                                    doc.text(
                                        lineService,
                                        20,
                                        280 + lineCount * 15
                                    )
                                }
                            )
                        } else {
                            lineCount++
                            doc.text(
                                'Zusatzleistungen: keine',
                                20,
                                280 + lineCount * 15
                            )
                        }
                        if (ticket.notes) {
                            let linesNotes: any = []
                            linesNotes = doc.splitTextToSize(ticket.notes, 500)
                            linesNotes?.forEach((line: string, i: number) => {
                                if (i === 0) {
                                    line = 'Bemerkungen: ' + line
                                }
                                lineCount++
                                doc.text(line, 20, 280 + lineCount * 15)
                            })
                        } else {
                            lineCount++
                            doc.text(
                                'Bemerkungen: keine',
                                20,
                                280 + lineCount * 15
                            )
                        }
                    }
                    lineCount++
                    doc.text(
                        '____________________________________________________________________________________________________',
                        20,
                        280 + lineCount * 15
                    )
                    doc.setFont('helvetica', 'bold')
                    doc.text(
                        'Informationen aus der nächsten Buchung',
                        20,
                        295 + lineCount * 15
                    )
                    doc.setFont('helvetica', 'normal')
                    if (ticket.nextAdults) {
                        const nextArrival = ticket.nextArrival
                            ? `Anreise: ${new Date(
                                  ticket.nextArrival
                              ).toLocaleDateString('DE')} um ${new Date(
                                  ticket.nextArrival
                              ).toLocaleTimeString('DE', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                              })} Uhr`
                            : 'keine Daten'
                        const nextDeparture = ticket.nextDeparture
                            ? `Abreise: ${new Date(
                                  ticket.nextDeparture
                              ).toLocaleDateString('DE')} um ${new Date(
                                  ticket.nextDeparture
                              ).toLocaleTimeString('DE', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                              })} Uhr`
                            : 'keine Daten'
                        lineCount++
                        doc.text(nextArrival, 20, 295 + lineCount * 15)
                        lineCount++
                        doc.text(nextDeparture, 20, 295 + lineCount * 15)
                        lineCount++
                        doc.text(
                            ticket.nextAdults
                                ? `Anzahl Erwachsene: ${ticket.nextAdults}`
                                : 'keine Daten',
                            20,
                            295 + lineCount * 15
                        )

                        let nextChilds: any = []
                        if (ticket.nextChilds && ticket.nextChilds.length > 0) {
                            ticket.nextChilds.forEach(
                                (nextChild: { age: number; count: number }) =>
                                    nextChilds.push(
                                        `${nextChild.age} Jahre (${nextChild.count}x)`
                                    )
                            )

                            let linesNextChilds: any = doc.splitTextToSize(
                                nextChilds.join(', '),
                                500
                            )
                            linesNextChilds?.forEach(
                                (lineNextChild: string, i: number) => {
                                    if (i === 0) {
                                        lineNextChild =
                                            'Anzahl Kinder: ' + lineNextChild
                                    }
                                    lineCount++
                                    doc.text(
                                        lineNextChild,
                                        20,
                                        295 + lineCount * 15
                                    )
                                }
                            )
                        } else {
                            lineCount++
                            doc.text(
                                'Anzahl Kinder: keine',
                                20,
                                295 + lineCount * 15
                            )
                        }
                        let nextServices: any = []
                        if (
                            ticket.nextAdditionalServices &&
                            ticket.nextAdditionalServices.length > 0
                        ) {
                            ticket.nextAdditionalServices.forEach(service => {
                                nextServices.push(
                                    `${service.as_name} (${service.as_count}x)`
                                )
                            })

                            let linesNextServices: any = doc.splitTextToSize(
                                nextServices.join(', '),
                                500
                            )
                            linesNextServices?.forEach(
                                (lineService: string, i: number) => {
                                    if (i === 0) {
                                        lineService =
                                            'Zusatzleistungen: ' + lineService
                                    }
                                    lineCount++
                                    doc.text(
                                        lineService,
                                        20,
                                        295 + lineCount * 15
                                    )
                                }
                            )
                        } else {
                            lineCount++
                            doc.text(
                                'Zusatzleistungen: keine',
                                20,
                                295 + lineCount * 15
                            )
                        }
                        if (ticket.nextNotes) {
                            let linesNextNotes: any = doc.splitTextToSize(
                                ticket.nextNotes,
                                500
                            )
                            linesNextNotes?.forEach(
                                (line: string, i: number) => {
                                    if (i === 0) {
                                        line = 'Bemerkungen: ' + line
                                    }
                                    lineCount++
                                    doc.text(line, 20, 295 + lineCount * 15)
                                }
                            )
                        } else {
                            lineCount++
                            doc.text(
                                'Bemerkungen: keine',
                                20,
                                295 + lineCount * 15
                            )
                        }
                    } else {
                        lineCount++
                        doc.text(
                            'keine weitere Buchung vorhanden',
                            20,
                            295 + lineCount * 15
                        )
                    }
                    lineCount++
                    doc.text(
                        '____________________________________________________________________________________________________',
                        20,
                        295 + lineCount * 15
                    )
                    doc.setFont('helvetica', 'bold')
                    lineCount++
                    doc.text('Reinigungsanweisung: ', 20, 295 + lineCount * 15)
                    doc.setFont('helvetica', 'normal')
                    if (ticket.content !== '') {
                        let contents = ticket.content?.split('\\n')
                        let lines: any = []
                        contents?.forEach((content: string) => {
                            lines.push(doc.splitTextToSize(content, 500))
                        })
                        lines?.forEach((line: string, i: number) => {
                            lineCount++
                            doc.text(line, 20, 295 + lineCount * 15)
                        })
                    } else {
                        lineCount++
                        doc.text('keine', 20, 295 + lineCount * 15)
                    }

                    doc.setFontSize(6)
                    doc.text(
                        'DS Task Manager',
                        300,
                        doc.internal.pageSize.height - 20
                    )
                    doc.text(
                        'Stand: ' +
                            now.toLocaleDateString('de-DE') +
                            ' um ' +
                            now.toLocaleTimeString('de-DE', {
                                hour: 'numeric',
                                minute: 'numeric',
                            }) +
                            ' Uhr',
                        280,
                        doc.internal.pageSize.height - 10
                    )
                }, pageTimeout * index)

                if (index < tickets.length - 1) {
                    setTimeout(() => {
                        doc.addPage()
                    }, pageTimeout * index + pageTimeout)
                }
            })

            setTimeout(() => {
                doc.save(getTicketFileName(EXPORT_FORMAT.PDF))
            }, pageTimeout * 2 * tickets.length + pageTimeout * tickets.length)
        }
    }

    return { exportPdfTickets }
}

export { useTickets, useTicketAlert, useExportTickets }
