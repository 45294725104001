import { Dispatch, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Col,
    Form,
    FormControl,
    InputGroup,
    OverlayTrigger,
    Spinner,
    Stack,
    Tooltip,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

import { ChangePasswordInput } from './types'
import { ResponseError, ResponseNotification } from 'common/types'
import { useChangePasswordMutation } from 'app/api'
import {
    cleanAuthState,
    selectCurrentAccessToken,
} from 'features/auth/authSlice'
import { changePasswordValidationSchema } from './validationSchema'

interface ChangePasswordFormProps {
    setResponseNotification: Dispatch<
        SetStateAction<ResponseNotification | null>
    >
    renderForm: boolean
    setRenderForm: Dispatch<SetStateAction<boolean>>
}

const ChangePasswordForm = ({
    setResponseNotification,
    renderForm,
    setRenderForm,
}: ChangePasswordFormProps) => {
    const dispatch = useDispatch()

    const [changePassword] = useChangePasswordMutation()

    const accessToken = useSelector(selectCurrentAccessToken)

    const {
        setFocus,
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors, isSubmitting, isSubmitted },
    } = useForm<ChangePasswordInput>({
        defaultValues: {
            password: '',
            newPassword: '',
            passwordConfirmation: '',
        },
        resolver: yupResolver(changePasswordValidationSchema),
    })
    const onSubmit = async ({ password, newPassword }: ChangePasswordInput) => {
        if (accessToken) {
            try {
                await changePassword({
                    accessToken,
                    password,
                    newPassword,
                }).unwrap()

                setResponseNotification({
                    status: 200,
                    message: 'Das Passwort wurde geändert.',
                })

                setRenderForm(false)
                reset()
            } catch (error) {
                const responseError = (error as ResponseError).data.error
                setResponseNotification(responseError)

                reset()
                setFocus('password')
            }
        } else {
            dispatch(cleanAuthState())
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction='vertical' gap={4} className='mb-4'>
                <Stack direction='horizontal'>
                    <InputGroup className='w-100'>
                        <InputGroup.Text
                            className={
                                isSubmitted
                                    ? errors.password?.message
                                        ? 'ds-form-is-invalid'
                                        : 'ds-form-is-valid'
                                    : ''
                            }
                        >
                            {errors.password ? (
                                <OverlayTrigger
                                    placement='top'
                                    overlay={props => (
                                        <Tooltip
                                            id='tooltip-password-account'
                                            {...props}
                                        >
                                            {errors.password?.message}
                                        </Tooltip>
                                    )}
                                >
                                    <i>
                                        <FontAwesomeIcon icon={faLock} />
                                    </i>
                                </OverlayTrigger>
                            ) : (
                                <i>
                                    <FontAwesomeIcon icon={faLock} />
                                </i>
                            )}
                        </InputGroup.Text>
                        <FormControl
                            type='password'
                            isValid={getValues('password') !== ''}
                            isInvalid={errors.password?.message ? true : false}
                            placeholder='Passwort'
                            aria-label='Passwort Feld'
                            {...register('password')}
                        />
                    </InputGroup>
                    <Col className='ms-auto align-self-end ps-3'>
                        <Button
                            type='button'
                            variant='secondary'
                            disabled={renderForm ? true : false}
                            onClick={() => {
                                setRenderForm(true)
                            }}
                        >
                            Ändern
                        </Button>
                    </Col>
                </Stack>
                {renderForm && (
                    <>
                        <InputGroup className='w-100'>
                            <InputGroup.Text
                                className={
                                    isSubmitted
                                        ? errors.newPassword?.message
                                            ? 'ds-form-is-invalid'
                                            : 'ds-form-is-valid'
                                        : ''
                                }
                            >
                                {errors.newPassword ? (
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={props => (
                                            <Tooltip
                                                id='tooltip-new-password-account'
                                                {...props}
                                            >
                                                {errors.newPassword?.message}
                                            </Tooltip>
                                        )}
                                    >
                                        <i>
                                            <FontAwesomeIcon icon={faLock} />
                                        </i>
                                    </OverlayTrigger>
                                ) : (
                                    <i>
                                        <FontAwesomeIcon icon={faLock} />
                                    </i>
                                )}
                            </InputGroup.Text>
                            <FormControl
                                type='password'
                                isValid={getValues('newPassword') !== ''}
                                isInvalid={
                                    errors.newPassword?.message ? true : false
                                }
                                placeholder='Neues Passwort'
                                aria-label='Neues Passwort Feld'
                                {...register('newPassword')}
                            />
                        </InputGroup>
                        <InputGroup className='w-100'>
                            <InputGroup.Text
                                className={
                                    isSubmitted
                                        ? errors.passwordConfirmation?.message
                                            ? 'ds-form-is-invalid'
                                            : 'ds-form-is-valid'
                                        : ''
                                }
                            >
                                {errors.passwordConfirmation ? (
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={props => (
                                            <Tooltip
                                                id='tooltip-password-confirmation-account'
                                                {...props}
                                            >
                                                {
                                                    errors.passwordConfirmation
                                                        ?.message
                                                }
                                            </Tooltip>
                                        )}
                                    >
                                        <i>
                                            <FontAwesomeIcon icon={faLock} />
                                        </i>
                                    </OverlayTrigger>
                                ) : (
                                    <i>
                                        <FontAwesomeIcon icon={faLock} />
                                    </i>
                                )}
                            </InputGroup.Text>
                            <FormControl
                                type='password'
                                isValid={
                                    getValues('passwordConfirmation') !== ''
                                }
                                isInvalid={
                                    errors.passwordConfirmation?.message
                                        ? true
                                        : false
                                }
                                placeholder='Neues Passwort wiederholen'
                                aria-label='Neues Passwort wiederholen Feld'
                                {...register('passwordConfirmation')}
                            />
                        </InputGroup>
                        <Stack direction='horizontal' gap={2} className='w-100'>
                            <Button
                                type='submit'
                                variant='primary'
                                aria-label='Passwort ändern'
                                className='w-50'
                            >
                                <Stack
                                    direction='horizontal'
                                    gap={2}
                                    className='justify-content-center'
                                >
                                    {isSubmitting && (
                                        <Spinner animation='border' size='sm' />
                                    )}
                                    <span>Speichern</span>
                                </Stack>
                            </Button>
                            <Button
                                type='submit'
                                variant='secondary'
                                onClick={() => {
                                    setRenderForm(false)
                                    reset()
                                }}
                                aria-label='Passwort-Änderung abbrechen'
                                className='w-50'
                            >
                                Abbrechen
                            </Button>
                        </Stack>
                    </>
                )}
            </Stack>
        </Form>
    )
}

export default ChangePasswordForm
