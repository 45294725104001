import { useSelector } from 'react-redux'

import Dashboard from './Dashboard'
import { selectCurrentProviderId } from 'features/auth/authSlice'

const DashboardPage = () => {
    const providerId = useSelector(selectCurrentProviderId)

    return <>{providerId && <Dashboard providerId={providerId} />}</>
}

export default DashboardPage
