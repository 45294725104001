import { Dispatch, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Col,
    Form,
    FormControl,
    Image,
    InputGroup,
    OverlayTrigger,
    Row,
    Spinner,
    Stack,
    Tooltip,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

import { SetFirstPasswordInput } from './types'
import {
    AuthChallenge,
    ResponseError,
    ResponseNotification,
} from 'common/types'
import {
    useGetProviderMutation,
    useGetUserMutation,
    useSetPasswordMutation,
} from 'app/api'
import { setProvider, setToken, setUser } from './authSlice'
import { setPasswordValidationSchema } from './validationSchema'

import loginImage from 'assets/images/header-login.png'

interface SetFirstPasswordFormProps {
    email: string
    authChallenge: AuthChallenge
    setAuthChallenge: Dispatch<SetStateAction<AuthChallenge | null>>
    setResponseNotification: Dispatch<
        SetStateAction<ResponseNotification | null>
    >
}

const SetFirstPasswordForm = ({
    email,
    authChallenge,
    setAuthChallenge,
    setResponseNotification,
}: SetFirstPasswordFormProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        setFocus,
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors, isSubmitting, isSubmitted },
    } = useForm<SetFirstPasswordInput>({
        defaultValues: {
            newPassword: '',
            passwordConfirmation: '',
        },
        resolver: yupResolver(setPasswordValidationSchema),
    })

    const [setPassword] = useSetPasswordMutation()
    const [getUser] = useGetUserMutation()
    const [getProvider] = useGetProviderMutation()

    const onSubmit = async (data: SetFirstPasswordInput) => {
        try {
            const setPasswordData = await setPassword({
                session: authChallenge.session,
                email,
                newPassword: data.newPassword,
            }).unwrap()

            setResponseNotification(null)

            dispatch(setToken(setPasswordData.token))

            const getUserData = await getUser({
                accessToken: setPasswordData.token.access,
            }).unwrap()
            dispatch(setUser(getUserData))

            const getProviderData = await getProvider(
                getUserData.providerId
            ).unwrap()
            dispatch(setProvider(getProviderData))

            navigate('/')
        } catch (error) {
            const responseError = (error as ResponseError).data.error
            setResponseNotification(responseError)

            reset()
            setFocus('newPassword')
        }
    }

    return (
        <Row className='justify-content-center'>
            <Col className='col-12 col-sm-10 col-md-8'>
                <div className='d-flex align-items-end'>
                    <div className='ms-auto flex-shrink-1'>
                        <Image
                            src={loginImage}
                            alt='Login'
                            className='img-fluid ds-login-image'
                        />
                    </div>
                    <div className='ds-padding-left-1 w-100'>
                        <Row className='align-items-center mb-3'>
                            <Col className='col-12 col-sm-12 col-md-6'>
                                <h4 className='mb-0'>Passwort festlegen</h4>
                            </Col>
                            <div className='d-block d-sm-block d-md-none ds-spacer'></div>
                            <Col className='col-12 col-sm-12 col-md-6 text-end'>
                                <Link
                                    to='#'
                                    onClick={() => {
                                        setAuthChallenge(null)
                                    }}
                                    className='ds-login-help-link'
                                    aria-label='Zurück zum Login Link'
                                >
                                    Zurück zur Login
                                </Link>
                            </Col>
                        </Row>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <InputGroup className='mb-3'>
                                <InputGroup.Text
                                    className={
                                        isSubmitted
                                            ? errors.newPassword?.message
                                                ? 'ds-form-is-invalid'
                                                : 'ds-form-is-valid'
                                            : ''
                                    }
                                >
                                    {errors.newPassword ? (
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={props => (
                                                <Tooltip
                                                    id='tooltip-new-password-set-password'
                                                    {...props}
                                                >
                                                    {
                                                        errors.newPassword
                                                            ?.message
                                                    }
                                                </Tooltip>
                                            )}
                                        >
                                            <i>
                                                <FontAwesomeIcon
                                                    icon={faLock}
                                                />
                                            </i>
                                        </OverlayTrigger>
                                    ) : (
                                        <i>
                                            <FontAwesomeIcon icon={faLock} />
                                        </i>
                                    )}
                                </InputGroup.Text>
                                <FormControl
                                    type='password'
                                    isValid={getValues('newPassword') !== ''}
                                    isInvalid={
                                        errors.newPassword?.message
                                            ? true
                                            : false
                                    }
                                    placeholder='Neues Nutzer Passwort'
                                    aria-label='Neues Nutzer Passwort Feld'
                                    {...register('newPassword')}
                                />
                            </InputGroup>
                            <InputGroup className='mb-3'>
                                <InputGroup.Text
                                    className={
                                        isSubmitted
                                            ? errors.passwordConfirmation
                                                  ?.message
                                                ? 'ds-form-is-invalid'
                                                : 'ds-form-is-valid'
                                            : ''
                                    }
                                >
                                    {errors.passwordConfirmation ? (
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={props => (
                                                <Tooltip
                                                    id='tooltip-password-confirmation-set-password'
                                                    {...props}
                                                >
                                                    {
                                                        errors
                                                            .passwordConfirmation
                                                            ?.message
                                                    }
                                                </Tooltip>
                                            )}
                                        >
                                            <i>
                                                <FontAwesomeIcon
                                                    icon={faLock}
                                                />
                                            </i>
                                        </OverlayTrigger>
                                    ) : (
                                        <i>
                                            <FontAwesomeIcon icon={faLock} />
                                        </i>
                                    )}
                                </InputGroup.Text>
                                <FormControl
                                    type='password'
                                    placeholder='Neues Passwort wiederholen'
                                    isValid={
                                        getValues('passwordConfirmation') !== ''
                                    }
                                    isInvalid={
                                        errors.passwordConfirmation?.message
                                            ? true
                                            : false
                                    }
                                    aria-label='Neues Passwort wiederholen Feld'
                                    {...register('passwordConfirmation')}
                                />
                            </InputGroup>
                            <Button
                                type='submit'
                                className='w-100'
                                aria-label='Nutzer einloggen'
                            >
                                <Stack
                                    direction='horizontal'
                                    gap={2}
                                    className='justify-content-center'
                                >
                                    {isSubmitting && (
                                        <Spinner animation='border' size='sm' />
                                    )}
                                    <span>Bestätigen</span>
                                </Stack>
                            </Button>
                        </Form>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default SetFirstPasswordForm
