import { Dispatch, SetStateAction, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Form,
    FormControl,
    FormLabel,
    InputGroup,
    Modal,
    OverlayTrigger,
    Spinner,
    Stack,
    Tooltip,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

import { ConfirmEmailChangeInput } from './types'
import { ResponseError, ResponseNotification } from 'common/types'
import ResponseAlert from 'common/alert/ResponseAlert'
import {
    useConfirmEmailChangeMutation,
    useGetProviderMutation,
    useGetUserMutation,
    useLoginMutation,
} from 'app/api'
import { setProvider, setToken, setUser } from './authSlice'
import { confirmEmailChangeValidationSchema } from './validationSchema'

interface ConfirmEmailChangeFormProps {
    email: string
    confirmationCode: string
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
}

const ConfirmEmailChangeForm = ({
    email,
    confirmationCode,
    showModal,
    setShowModal,
}: ConfirmEmailChangeFormProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [
        responseNotification,
        setResponseNotification,
    ] = useState<ResponseNotification | null>(null)

    const {
        setFocus,
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors, isSubmitting, isSubmitted },
    } = useForm<ConfirmEmailChangeInput>({
        defaultValues: { password: '' },
        resolver: yupResolver(confirmEmailChangeValidationSchema),
    })

    const [login] = useLoginMutation()
    const [confirmEmailChange] = useConfirmEmailChangeMutation()
    const [getUser] = useGetUserMutation()
    const [getProvider] = useGetProviderMutation()

    const onSubmit = async ({ password }: ConfirmEmailChangeInput) => {
        try {
            const loginData = await login({ email, password }).unwrap()

            if (loginData.token) {
                dispatch(setToken(loginData.token))

                await confirmEmailChange({
                    accessToken: loginData.token.access,
                    confirmationCode,
                }).unwrap()

                const getUserData = await getUser({
                    accessToken: loginData.token.access,
                }).unwrap()
                dispatch(setUser(getUserData))

                const getProviderData = await getProvider(
                    getUserData.providerId
                ).unwrap()
                dispatch(setProvider(getProviderData))

                setShowModal(false)
                reset()
            }
        } catch (error) {
            const responseError = (error as ResponseError).data.error
            setResponseNotification(responseError)

            reset()
            setFocus('password')
        }
    }

    return (
        <Modal
            show={showModal}
            onHide={() => {
                navigate('/')
            }}
        >
            <Modal.Header
                closeButton
                onClick={() => {
                    navigate('/')
                }}
            />
            <Modal.Body>
                <h4 className='mb-3'>E-Mail Änderung bestätigen</h4>
                {responseNotification && (
                    <ResponseAlert
                        status={responseNotification.status}
                        message={responseNotification.message}
                    />
                )}
                Sie möchten Ihre E-Mail ändern? Bestätigen Sie den Einsatz.
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='d-flex mt-3 mb-3'>
                        <div className='w-100'>
                            <FormLabel htmlFor='change-email-input-field'>
                                E-Mail Änderung
                            </FormLabel>
                            <InputGroup>
                                <InputGroup.Text
                                    className={
                                        isSubmitted
                                            ? errors.password?.message
                                                ? 'ds-form-is-invalid'
                                                : 'ds-form-is-valid'
                                            : ''
                                    }
                                >
                                    {errors.password ? (
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={props => (
                                                <Tooltip
                                                    id='tooltip-change-email-password'
                                                    {...props}
                                                >
                                                    {errors.password?.message}
                                                </Tooltip>
                                            )}
                                        >
                                            <i>
                                                <FontAwesomeIcon
                                                    icon={faLock}
                                                />
                                            </i>
                                        </OverlayTrigger>
                                    ) : (
                                        <i>
                                            <FontAwesomeIcon icon={faLock} />
                                        </i>
                                    )}
                                </InputGroup.Text>
                                <FormControl
                                    id='change-email-input-field'
                                    type='password'
                                    isValid={getValues('password') !== ''}
                                    isInvalid={
                                        errors.password?.message ? true : false
                                    }
                                    placeholder='Nutzer Passwort'
                                    aria-label='Nutzer Passwort Feld'
                                    {...register('password')}
                                />
                            </InputGroup>
                        </div>
                        <div className='ms-auto align-self-end ps-3'>
                            <Button
                                type='submit'
                                aria-label='Nutzer-Änderung bestätigen'
                            >
                                <Stack
                                    direction='horizontal'
                                    gap={2}
                                    className='justify-content-center'
                                >
                                    {isSubmitting && (
                                        <Spinner animation='border' size='sm' />
                                    )}
                                    <span>Bestätigen</span>
                                </Stack>
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    className='px-4 w-100'
                    onClick={() => {
                        navigate('/')
                    }}
                    aria-label='Passwort-Änderung Form schließen'
                >
                    Schließen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmEmailChangeForm
