const mapVariant = (status: number) => {
    switch (status) {
        case 200:
            return 'success'
        case 400:
            return 'danger'
        default:
            return
    }
}

export { mapVariant }
