import { Dispatch, SetStateAction } from 'react'
import { Col, InputGroup, FormControl, Stack } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons'

import { FilterTicketByDate } from 'features/dashboard/types'

interface FilterByDateProps {
    filterValue: FilterTicketByDate
    setFilterValue: Dispatch<SetStateAction<FilterTicketByDate>>
}

const FilterByDateBar = ({
    filterValue,
    setFilterValue,
}: FilterByDateProps) => (
    <Col className='col-12 col-sm-7 col-md-6 col-lg-5 col-xl-4 col-xxl-3'>
        <InputGroup>
            <Stack direction='horizontal'>
                <InputGroup.Text>
                    <i>
                        <FontAwesomeIcon icon={faCalendarWeek} />
                    </i>
                </InputGroup.Text>
                <DatePicker
                    className='ds-date-picker-inline'
                    selected={filterValue.startDate}
                    onChange={(date: Date) => {
                        setFilterValue({ ...filterValue, startDate: date })
                    }}
                    placeholderText='Datum von'
                    selectsStart
                    customInput={
                        <FormControl type='text' className='text-center' />
                    }
                    dateFormat='dd.MM.yyyy'
                    filterDate={date => date <= filterValue.endDate}
                />
                <InputGroup.Text>-</InputGroup.Text>
                <DatePicker
                    className='ds-date-picker-inline'
                    selected={filterValue.endDate}
                    onChange={(date: Date) =>
                        setFilterValue({ ...filterValue, endDate: date })
                    }
                    placeholderText='Datum bis'
                    customInput={
                        <FormControl type='text' className='text-center' />
                    }
                    dateFormat='dd.MM.yyyy'
                    filterDate={date => date >= filterValue.startDate}
                />
            </Stack>
            {/* <InputGroup.Text>-</InputGroup.Text> */}
            {/* <FormControl */}
            {/*     type='text' */}
            {/*     value={filterValue.endDate.toLocaleDateString('DE')} */}
            {/*     placeholder='Datum von' */}
            {/* /> */}
        </InputGroup>
    </Col>
)

export default FilterByDateBar
