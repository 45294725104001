import { Dispatch, SetStateAction, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Form,
    FormControl,
    FormLabel,
    InputGroup,
    Modal,
    OverlayTrigger,
    Spinner,
    Stack,
    Tooltip,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

import { ConfirmPasswordChangeInput } from './types'
import { ResponseError, ResponseNotification } from 'common/types'
import ResponseAlert from 'common/alert/ResponseAlert'
import { useConfirmPasswordChangeMutation } from 'app/api'
import { setPasswordValidationSchema } from './validationSchema'

interface ConfirmPasswordChangeFormProps {
    email: string
    confirmationCode: string
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
    setLoginResponseNotification: Dispatch<
        SetStateAction<ResponseNotification | null>
    >
}

const ConfirmPasswordChangeForm = ({
    email,
    confirmationCode,
    showModal,
    setShowModal,
    setLoginResponseNotification,
}: ConfirmPasswordChangeFormProps) => {
    const navigate = useNavigate()

    const [
        responseNotification,
        setResponseNotification,
    ] = useState<ResponseNotification | null>(null)

    const {
        setFocus,
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors, isSubmitting, isSubmitted },
    } = useForm<ConfirmPasswordChangeInput>({
        defaultValues: { newPassword: '', passwordConfirmation: '' },
        resolver: yupResolver(setPasswordValidationSchema),
    })

    const [confirmPasswordChange] = useConfirmPasswordChangeMutation()

    const onSubmit = async ({ newPassword }: ConfirmPasswordChangeInput) => {
        try {
            await confirmPasswordChange({
                email,
                newPassword,
                confirmationCode,
            }).unwrap()

            setLoginResponseNotification({
                status: 200,
                message: 'Das Passwort wurde geändert.',
            })

            setShowModal(false)
            reset()
        } catch (error) {
            const responseError = (error as ResponseError).data.error
            setResponseNotification(responseError)

            reset()
            setFocus('newPassword')
        }
    }

    return (
        <Modal
            show={showModal}
            onHide={() => {
                navigate('/')
            }}
        >
            <Modal.Header
                closeButton
                onClick={() => {
                    navigate('/')
                }}
            />
            <Modal.Body>
                <h4 className='mb-3'>Passwort Änderung bestätigen</h4>
                {responseNotification && (
                    <ResponseAlert
                        status={responseNotification.status}
                        message={responseNotification.message}
                    />
                )}
                Sie möchten Ihre E-Mail ändern? Bestätigen Sie den Einsatz.
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='d-flex mt-3'>
                        <div className='w-100'>
                            <FormLabel htmlFor='change-email-input-field'>
                                Passwort Änderung
                            </FormLabel>
                            <Stack direction='vertical' gap={2}>
                                <InputGroup>
                                    <InputGroup.Text
                                        className={
                                            isSubmitted
                                                ? errors.newPassword?.message
                                                    ? 'ds-form-is-invalid'
                                                    : 'ds-form-is-valid'
                                                : ''
                                        }
                                    >
                                        {errors.newPassword ? (
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={props => (
                                                    <Tooltip
                                                        id='tooltip-change-email-password'
                                                        {...props}
                                                    >
                                                        {
                                                            errors.newPassword
                                                                ?.message
                                                        }
                                                    </Tooltip>
                                                )}
                                            >
                                                <i>
                                                    <FontAwesomeIcon
                                                        icon={faLock}
                                                    />
                                                </i>
                                            </OverlayTrigger>
                                        ) : (
                                            <i>
                                                <FontAwesomeIcon
                                                    icon={faLock}
                                                />
                                            </i>
                                        )}
                                    </InputGroup.Text>
                                    <FormControl
                                        id='change-password-new-password-input-field'
                                        type='password'
                                        isValid={
                                            getValues('newPassword') !== ''
                                        }
                                        isInvalid={
                                            errors.newPassword?.message
                                                ? true
                                                : false
                                        }
                                        placeholder='Neues Nutzer Passwort'
                                        aria-label='Neues Nutzer Passwort Feld'
                                        {...register('newPassword')}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Text
                                        className={
                                            isSubmitted
                                                ? errors.passwordConfirmation
                                                      ?.message
                                                    ? 'ds-form-is-invalid'
                                                    : 'ds-form-is-valid'
                                                : ''
                                        }
                                    >
                                        {errors.passwordConfirmation ? (
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={props => (
                                                    <Tooltip
                                                        id='tooltip-change-password-password-confirmation'
                                                        {...props}
                                                    >
                                                        {
                                                            errors
                                                                .passwordConfirmation
                                                                ?.message
                                                        }
                                                    </Tooltip>
                                                )}
                                            >
                                                <i>
                                                    <FontAwesomeIcon
                                                        icon={faLock}
                                                    />
                                                </i>
                                            </OverlayTrigger>
                                        ) : (
                                            <i>
                                                <FontAwesomeIcon
                                                    icon={faLock}
                                                />
                                            </i>
                                        )}
                                    </InputGroup.Text>
                                    <FormControl
                                        id='change-password-password-confirmation-input-field'
                                        type='password'
                                        isValid={
                                            getValues(
                                                'passwordConfirmation'
                                            ) !== ''
                                        }
                                        isInvalid={
                                            errors.passwordConfirmation?.message
                                                ? true
                                                : false
                                        }
                                        placeholder='Neues Nutzer Passwort wiederholen'
                                        aria-label='Neues Nutzer Passwort wiederholen Feld'
                                        {...register('passwordConfirmation')}
                                    />
                                </InputGroup>
                                <Button
                                    type='submit'
                                    className='mt-3'
                                    aria-label='Nutzer-Änderung bestätigen'
                                >
                                    <Stack
                                        direction='horizontal'
                                        gap={2}
                                        className='justify-content-center'
                                    >
                                        {isSubmitting && (
                                            <Spinner
                                                animation='border'
                                                size='sm'
                                            />
                                        )}
                                        <span>Bestätigen</span>
                                    </Stack>
                                </Button>
                            </Stack>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    className='px-4 w-100'
                    onClick={() => {
                        navigate('/')
                    }}
                    aria-label='Passwort-Änderung Form schließen'
                >
                    Schließen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmPasswordChangeForm
