import { EXPORT_FORMAT, TICKET_STATUS, Ticket, Address } from 'common/types'
import { UseFormRegister } from 'react-hook-form'

// expire cookies after a week
const TICKET_COOKIES_EXPIRATION_DAYS = 7 * 24 * 60 * 60 * 1000

enum SEARCH_BY_KEY {
    BS_ROOM_NAME = 'bsRoomName',
}

enum FILTER_BY_STATUS {
    Offen,
    'Bearbeitung',
    Fertig,
}

enum UPDATE_STATUS {
    Offen,
    'Bearbeitung',
    Fertig,
}

enum GROUP_BY {
    BS_ROOM_NAME = 'bsRoomName',
    BS_OBJECT_NAME = 'bsObjectName',
}

enum EXECUTION_DAY {
    SOON,
    TODAY,
    TOMORROW,
    DAY_AFTER_TOMORROW,
}

interface FilterTicketByDate {
    startDate: Date
    endDate: Date
}

interface MappedTicket extends Ticket {
    bsObjectId?: number
    bsObjectName?: string
    bsRoomName?: string
    bsRoomId?: number
    address?: Address
}

interface TicketItemProps {
    ticket: MappedTicket
    index: number
    register: UseFormRegister<ExportInput>
}

interface TicketsGroup {
    groupId: string
    tickets: MappedTicket[]
}

interface WeekTicketsGroup {
    [key: string]: Ticket[]
}

interface ExportInput {
    [key: string]: string
}

interface UpdateTicketInput {
    newStatus?: TICKET_STATUS
    newPlannedDate?: Date
}

export {
    SEARCH_BY_KEY,
    FILTER_BY_STATUS,
    UPDATE_STATUS,
    GROUP_BY,
    EXECUTION_DAY,
    EXPORT_FORMAT,
    TICKET_COOKIES_EXPIRATION_DAYS,
}
export type {
    FilterTicketByDate,
    MappedTicket,
    TicketsGroup,
    WeekTicketsGroup,
    ExportInput,
    UpdateTicketInput,
    TicketItemProps,
}
